import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesEventBloom: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tomorrow is a Blooming Day</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_event_1.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Tomorrow is a Blooming Day Event Guide</h1>
          <h2>
            A guide for the second event available in Ash Echoes Global version
            - Tomorrow is a Blooming Day.
          </h2>
          <p>
            Last updated: <strong>30/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Event Overview" />
        <StaticImage
          src="../../../images/ash/event/bloom_1.webp"
          alt="Guides"
        />
        <p>
          In this event, you use dice to advance through the squares in the
          Parade Path:
        </p>
        <ul>
          <li>
            Golden tiles are collected automatically as you pass over them,
            initiating a story section and giving Frostfare Coins and Extra
            Dice.
          </li>
          <li>Red coin tiles give a small amount of Frostfare Coins.</li>
          <li>
            Red sword tiles begins a combat encounter which awards more
            Frostfare Coins.
          </li>
          <li>Blue Gift tiles give X Particle currency.</li>
          <li>Blue Dice tiles give extra Dice.</li>
        </ul>
        <p>
          You can also earn Frostfare Coins and Dice through completing Parade
          Quests.
        </p>
        <p>
          By completing each chapter of the Event Story, you'll unlock higher
          difficulty versions of the Event Boss, Clash of Floats.
        </p>
        <p>Check out our Guide for it below:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Boss Challenge: Clash of Floats"
            link="/ash-echoes/guides/boss-challenge-clash-of-floats"
            image={
              <StaticImage
                src="../../../images/ash/categories/category_float.webp"
                alt="Boss Challenge: Clash of Floats"
              />
            }
            isNew
          />
        </Row>
        <p>
          Additionally, completing quests and acquiring all the items from the
          Anecdote can give extra rewards like a medal and event furniture.
        </p>
        <SectionHeader title="The Luck Shop" />
        <StaticImage
          src="../../../images/ash/event/bloom_2.webp"
          alt="Guides"
        />
        <p>
          Here is where you spend all those Frostfare Coins. The order of
          priority is simple as usual, try to prioritize the exclusive event
          Memory Trace - <AshTrace name="Festive Night" mode="data" onProfile />{' '}
          and its dupes first, then the Resonance Clue + Momentary Backflash,
          the decorations, and the rest depending on your current needs.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesEventBloom;

export const Head: React.FC = () => (
  <Seo
    title="Tomorrow is a Blooming Day | Ash Echoes | Prydwen Institute"
    description="A guide for the second event available in Ash Echoes Global version."
    game="ash"
  />
);
